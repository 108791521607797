import React from "react";
import PropTypes from "prop-types";
import HeroSlider, {  Overlay, Slide } from "hero-slider";
import HeroWrapper from "../HeroWrapper/herowrapper";
import HeroTitle from "../HeroTitle/herotitle";
import HeroSubtitle from "../HeroSubtitle/herosubtitle";
//import * as  classes from "./heroimage.module.css";
import _ from 'lodash';


const HeroImage = ({image, title, description}) => {

  return (
    <HeroSlider
      style={{
              backgroundColor: "#000"}}
      slidingAnimation="top_to_bottom"
      orientation="vertical"
      initialSlide={1}
      onBeforeChange={(previousSlide, nextSlide) =>
        console.log("onBeforeChange", previousSlide, nextSlide)
      }
      onChange={(nextSlide) => console.log("onChange", nextSlide)}
      onAfterChange={(nextSlide) => console.log("onAfterChange", nextSlide)}
      settings={{
        height: "90vh"
      }}
    >
    <Overlay>
       <HeroWrapper>
           <HeroTitle>{title}</HeroTitle>
           <HeroSubtitle>{description}</HeroSubtitle>
       </HeroWrapper>
    </Overlay>
    <Slide
      key={title}
      shouldRenderMask
      label={ title }
      navDescription={ description }
      background={{
        backgroundImageSrc:  image,
        backgroundBlendMode: "luminosity",
        maskBackgroundBlendMode: "luminosity",
      }}
    />

    </HeroSlider>
  );
}

HeroImage.defaultProps = {
  image: '',
  title: '',
  description: ''
}

HeroImage.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string
}
export default HeroImage;
