import React from "react";
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from 'gatsby'
import _ from 'lodash';

const Service = ({ description, header, link }) => {
    let displayHeader = ( header == null || header ) ? true : false
    let displayLink = ( link == null || link ) ? true : false

    const data = useStaticQuery(graphql`
      query Services {
        services: allStrapiService {
          nodes {
            body {
              data {
                body
              }
            }
            id
            description
            date
            page
            slug
            index
            title
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }

      }
    `)

    let services =   _.orderBy( data.services.nodes.map( item =>
      _.merge( _.pick(item,['id','date','title','description','section','page','slug','index']),
               _.pick(item.body.data, ['body'] ),
               { image: item.image.localFile})), ['index'],['asc'] )

    return (
      <div className="service section" id="Service">
        <div className="container">
          <div className="section-head">
            { displayHeader ? <h2>Services</h2> : <span></span> }
          </div>
          <div className="section-description">
             <h3>{description}</h3>
          </div>
          <div className="row">
            {services.map((item, index) => {
              //console.debug( item, index );
              if ( true ) {
              return (
                <div key={item.title} className="col-md-4 mb-3  d-flex align-items-stretch">
                  <div className="service-main card ">
                    <GatsbyImage
                      image={item.image.childImageSharp.gatsbyImageData}
                      alt="image"
                      objectFit="cover"
                      objectPosition="50% 50%"
                      className="card-img"
                    />
                    <div className="card-img-overlay d-flex flex-column text-white align-items-end overlay-dark ">
                      <div className="card-body ">
                      <h4 className="card-title ">{item.title}</h4>
                      <p className="card-text mb-3" >
                        {item.description}
                      </p>

                    </div>
                      <Link className="btn  btn-light mt-auto align-self-start" to={`/${item.slug}`} >More...</Link>
                    </div>

                  </div>
                </div>
              )
            } else if (false) {
              return (
                <div key={item.title} className="col-md-4 mb-3  d-flex align-items-stretch">
                  <div className="service-main card">

                    <GatsbyImage
                      image={item.image.childImageSharp.gatsbyImageData}
                      alt="image"
                      objectFit="cover"
                      objectPosition="50% 50%"
                      className="card-img-top"
                    />
                    <div className="card-body d-flex flex-column">
                      <h3 className="card-title">{item.title}</h3>
                      <p className="card-text mb-3" >
                        {item.description}
                      </p>
                      <Link className="btn  btn-outline-secondary mt-auto align-self-start" to={`/${item.slug}`} >More...</Link>
                    </div>

                  </div>
                </div>
              )
            } else {
              return(
                <div key={item.title} className="col-md-4 mb-3 d-flex align-items-stretch">
                  <div className="service-main">
                    <h3>{item.title}</h3>
                    <GatsbyImage
                      image={item.image.childImageSharp.gatsbyImageData}
                      alt="image"
                      objectFit="cover"
                      objectPosition="50% 50%"
                    />
                      <div>
                      {item.description}
                      </div>
                      <div className="see-detail">
                        <Link to={`/${item.slug}`}>
                          <span>More ></span>
                        </Link>
                      </div>
                  </div>
                </div>
              )
            }
            })}
          </div>
         { displayLink ?
            <div className="see-more">
              <Link to="/services">
                <span>Services</span>
              </Link>
            </div>
        :
            <span></span>
        }
        </div>
      </div>
    );
  }

Service.propTypes = {
  description: PropTypes.string,
  header: PropTypes.bool,
  link: PropTypes.bool
}

Service.defaultProps = {
  description: "",
  header: true,
  link: true
}

export default Service;
