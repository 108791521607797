import React from "react";
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from 'gatsby'
import _ from 'lodash';

const ServiceMenu = ({ title, description }) => {

    const data = useStaticQuery(graphql`
      query ServiceMenu {
        services: allStrapiService {
          nodes {
            body {
              data {
                body
              }
            }
            id
            description
            date
            page
            slug
            title
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }

      }
    `)

    let services =   data.services.nodes.map( item =>
      _.merge( _.pick(item,['id','date','title','description','section','page','slug']),
               _.pick(item.body.data, ['body'] ),
               { image: item.image.localFile})
    )


    return (
          <div className="container" >
              <div className="section-head"><h3 className="submenu-title">{title}</h3></div>
              { (description) ? <div className="submenu-description"><h4>{description}</h4></div> : <div/> }
              <div className="row">
                {services.map((item, index) => {
                  return (
                    <div key={item.title} className="col-md-4 mb-3  d-flex align-items-stretch" >
                       <div className="submenu-item card">
                          <GatsbyImage key={item.slug} image={item.image.childImageSharp.gatsbyImageData} alt="image" objectFit="cover" objectPosition="50% 50%" className="card-img"  />
                          <div className="card-img-overlay d-flex flex-column text-white justify-content-center align-items-end ">
                            <div className="card-body overlay-dark">
                              <h4 className="card-title ">{item.title}</h4>
                              <p className="card-text mb-3" >{item.description}</p>
                            </div> {/* card-body */}
                            <Link key={item.slug} className="btn  btn-light mt-auto align-self-start" to={`/${item.slug}`} >More...</Link>
                          </div> {/* card-img-overlay */}
                      </div> {/* submenu-item */}
                    </div>
                  );
                })}
              </div>
          </div>
    );
  }

ServiceMenu.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string
}

ServiceMenu.defaultProps = {
  title: "",
  description: null
}

export default ServiceMenu;
